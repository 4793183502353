/* global Component */
class PaymentsResult extends Component {

    static name(){
        return "paymentsResultPage";
    }

    static componentName() {
        return 'paymentsResultPage';
    }

    getProps() {
        return ['orderSerNr'];
    }

    data() {
        return {
            mpdata : {},
            paymentMsg:null,
        };
    }

    getRoutes() {
        return {};
    }

    getWatch() {
        return {
            '$store.getters.getAppState':function (param1,param2){
                if(this.$store.getters.getAppState == 'ready'){
                    if(this.$store.state.session) {
                        this.loadPaymentData();
                        this.appIsReady = true;
                    }
                    else{
                        this.$store.dispatch('setRedirectLogin',this.$route.fullPath);
                        this.$router.push({name:'LoginLink'});
                    }
                }
            }
        };
    }

    mounted(){
        return async function () {
            if(this.$store.getters.getAppState == 'ready'){
                this.loadPaymentData();
                this.appIsReady = true;
            }
        };
    }

    orderSerNr(){
        // console.log('computed SerNr');
    }

    getMethods(){
        return {
            loadPaymentData:this.loadPaymentData
        };
    }

    loadPaymentData(){
        this.mpdata = JSON.parse(atob(this.$route.params.info));
    }

    getComputed() {
        return {
            soSerNr:function (){
                if (this.$route.params.orderid)
                    return atob(this.$route.params.orderid);
                return this.orderSerNr;
            },
            paymentLink:function (){
                return "/payment/" + window.btoa(this.mpdata.order);
            }
        };
    }

    getTemplate() {
        return `<div>
                  <headerSectionComponent></headerSectionComponent>
                  <div :class="classContainer" >
                      <div class="section-main col-lg-8 offset-lg-2" v-if="!isLoading">
                           <h5 class="text-center my-4">{{tr("Payment")}} {{tr('Sales Order')}} <strong>{{mpdata.order}}</strong></h5>                           
                            <template v-if="mpdata.status=='approved'">
                                <div class="col-12 col-md-8 offset-md-2 alert alert-success text-center" role="alert">
                                    <icon name="regular/check-circle" scale="5" class="col-6"/>
                                    <h4 class="col-12 text-center mt-2">{{tr("The payment was approved successfully")}}</h4>
                                    <strong class="d-block mt-2">{{tr('It may take a few minutes to be reflected')}}</strong>
                                    <router-link to="/profile" class="btn btn-success mt-2">{{tr('View Shopping List')}}</router-link>
                                </div>
                            </template>
                            <template v-if="mpdata.status=='in_process'">
                                <div class="col-12 col-md-8 offset-md-2 alert alert-warning text-center" role="alert">
                                    <icon name="regular/check-circle" scale="5" class="col-6"/>
                                    <h4 class="col-12 text-center mt-2">{{tr("Your payment is in process, once it has been credited to our account, you will be notified")}}.</h4>
                                    <strong class="d-block mt-2">{{tr('It may take a few minutes to be reflected')}}</strong>
                                    <router-link to="/profile" class="btn btn-warning mt-2">{{tr('View Shopping List')}}</router-link>
                                </div>
                            </template>
                            <template v-if="mpdata.status=='rejected' || mpdata.status=='null'">
                                <div class="col-12 col-md-8 offset-md-2 alert alert-warning text-center" role="alert">
                                    <icon name="regular/check-circle" scale="5" class="col-6"/>
                                    <h4 class="col-12 text-center mt-2">{{tr("Sorry your payment was rejected or canceled manually by you, if you payment was reject you could try with other payment method clicking in the link below")}}.</h4>
                                    <router-link :to="paymentLink" class="btn btn-warning mt-2">{{tr('Try Again')}}</router-link>
                                </div>
                            </template>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
              </div>`;

    }
}

PaymentsResult.registerComponent();
